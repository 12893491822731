import React from "react"

import Hero from "./Hero"
import Menu from "./Menu"


const Landing = () =>
  <>
    <Hero/>
    <Menu/>
  </>

export default Landing